const images = [
  "images/img-01.jpg",
  "images/img-02.jpg",
  "images/img-03.jpg",
  "images/img-04.jpg",
  "images/img-05.png",
  "images/img-06.jpg",
  "images/img-07.jpg",
  "images/img-08.jpg",
  "images/img-09.jpg",
  "images/img-10.jpg",
  "images/img-11.jpg",
  "images/img-12.jpg",
  "images/img-13.jpg",
  "images/img-14.jpg",
  "images/img-15.jpg",
  "images/img-16.jpg",
  "images/img-17.jpg",
  "images/img-18.jpg",
  "images/img-19.jpg",
  "images/img-20.jpg",
  "images/img-21.jpg",
  "images/img-22.jpg",
  "images/img-23.jpg",
  "images/img-24.jpg",
  "images/img-25.jpg",
  "images/img-26.jpg",
  "images/img-27.jpg",
  "images/img-28.jpg",
  "images/img-29.jpg",
  "images/img-30.jpg",
];

const videos = [
  "videos/vid-01.mp4",
  "videos/vid-02.mp4",
  "videos/vid-03.mp4",
  "videos/vid-04.mp4",
  "videos/vid-05.mp4",
  "videos/vid-06.mp4",
];

this.addEventListener("DOMContentLoaded", preloadImages, true);

function preloadImages(e) {
  for (var i = 0; i < images.length; i++) {
    var tempImage = new Image();
    tempImage.src = images[i];
  }
}

let i = Math.floor(Math.random(0, 24));
let j = Math.floor(Math.random(0, 5));

function placeImage(x, y) {
  const nextImage = images[i];

  const img = document.createElement("img");
  img.setAttribute("src", nextImage);
  img.style.left = x + "px";
  img.style.top = y + "px";
  img.style.transform =
    "translate(-50%, -50%) scale(0.5) rotate(" + (Math.random() * 10 - 5) + "deg)";

  document.body.appendChild(img);

  i = i + 1;

  if (i >= images.length) {
    i = 0;
  }
}

function placeVideo(x, y) {
  const nextVideo = videos[j];

  const vid = document.createElement("video");
  vid.setAttribute("src", nextVideo);
  vid.muted = true;
  vid.autoplay = true;
  vid.loop = true;
  vid.preload = true;
  vid.style.left = x + "px";
  vid.style.top = y + "px";
  vid.style.transform =
    "translate(-50%, -50%) scale(0.5) rotate(" + (Math.random() * 10 - 5) + "deg)";

  document.body.appendChild(vid);

  j = j + 1;

  if (j >= videos.length) {
    j = 0;
  }
}

const info = document.querySelector(".nav-info");

info.addEventListener("click", function (e) {
  e.preventDefault();
  window.location.replace("info.html");
});

document.addEventListener("click", function (e) {
  e.preventDefault();
  Math.random() <= 0.3 ? placeVideo(e.pageX, e.pageY) : placeImage(e.pageX, e.pageY);
});

document.addEventListener("touchend", function (e) {
  e.preventDefault();
  Math.random() <= 0.3 ? placeVideo(e.pageX, e.pageY) : placeImage(e.pageX, e.pageY);
});
